import Image from "next/image";
import Link from "next/link";
import React from "react";

const FooterAppAds = () => {
  const title = "Sekarang sudah tersedia Aplikasi Rumahdewi!";
  const description =
    "Maksimalkan eksposur iklan Anda dengan Aplikasi Rumahdewi, Pasang iklan Anda sekarang dan nikmati keuntungannya dengan Aplikasi Rumahdewi!";
  const webDownload =
    "https://play.google.com/store/apps/details?id=com.rumahdewiagent.app";
  return (
    // <div className="footer-bg-img flex-grow-1 position-sticky bottom-0">
    <>
      <div
        className="d-block d-md-none position-relative"
        style={{ height: "828px" }}
      >
        <Image
          src="/assets/ads-footer-new-background-mobile.png"
          objectFit="contain"
          layout="fill"
          placeholder="blur"
          blurDataURL="/assets/ads-footer-new-background-mobile.png"
        />

        <div className="position-relative container d-flex flex-column">
          <div
            className="position-relative flex-grow-1"
            style={{ height: "320px", width: "300px", left: 35, top: 80 }}
          >
            <Image
              src="/assets/ads-footer-phone-background.png"
              layout="fill"
              placeholder="blur"
              blurDataURL="/assets/ads-footer-phone-background.png"
            />
          </div>

          <div
            className="position-relative d-flex flex-column justify-content-between gap-2"
            style={{ top: 120 }}
          >
            <div className="text-white">
              <p className="font-roboto h2 fw-bold">{title}</p>
              <p>{description}</p>
            </div>

            <div>
              <Link href={webDownload}>
                <Image
                  src="/assets/gplay.png"
                  alt="google play logo"
                  width={163}
                  height={48}
                  placeholder="blur"
                  blurDataURL="/assets/gplay.png"
                  className="cursor-pointer"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-none d-md-block position-relative"
        style={{ height: "380px" }}
      >
        <Image
          src="/assets/ads-footer-new-background.png"
          objectFit="contain"
          layout="fill"
          placeholder="blur"
          blurDataURL="/assets/ads-footer-new-background.png"
        />

        <div className="position-relative container d-flex align-items-start gap-4 h-100">
          <div
            className="position-relative flex-grow-1"
            style={{ height: "350px", top: 38 }}
          >
            <Image
              src="/assets/ads-footer-phone-background.png"
              layout="fill"
              placeholder="blur"
              blurDataURL="/assets/ads-footer-phone-background.png"
            />
          </div>

          <div
            className="position-relative d-flex flex-column justify-content-between gap-2"
            style={{ width: "853px", top: 70 }}
          >
            <div className="text-white">
              <p className="font-roboto h2 fw-bold">{title}</p>
              <p>{description}</p>
            </div>

            <div>
              <Link href={webDownload}>
                <Image
                  src="/assets/gplay.png"
                  alt="google play logo"
                  width={163}
                  height={48}
                  placeholder="blur"
                  blurDataURL="/assets/gplay.png"
                  className="cursor-pointer"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterAppAds;
