import Image from "next/image";
import Link from "next/link";
import React from "react";

export default function Footer(props) {
  const { isLanding } = props;
  return (
    <footer>
      <div
        className={`py-3 text-white position-relative ${
          !isLanding ? "bg-white shadow-sm" : ""
        }`}
      >
        <Image
          src="/assets/footer-background.jpg"
          alt="footer background"
          layout="fill"
          objectFit="cover"
          placeholder="blur"
          blurDataURL="/assets/footer-background.jpg"
        />

        <div
          className="object-fit-fill w-100 h-100 position-absolute top-0"
          style={{
            background: "#21314A",
            opacity: 0.9,
          }}
        />

        <div className="position-relative container d-flex flex-md-column justify-content-md-start justify-content-between gap-2">
          <div className="d-flex gap-4 align-items-center justify-content-between py-2">
            <Link href="/">
              <a>
                <div className="d-none d-md-block">
                  <Image
                    src="/logo_rmd.png"
                    alt="logo rumahdewi"
                    width="200px"
                    height="60px"
                    quality={100}
                    placeholder="blur"
                    blurDataURL="/logo_rmd.png"
                  />
                </div>
                <div className="d-block d-md-none">
                  <Image
                    src={"/logo_rmd.png"}
                    alt="logo rumahdewi"
                    width="150px"
                    height="50px"
                    quality={100}
                    placeholder="blur"
                    blurDataURL="/logo_rmd.png"
                  />
                </div>
              </a>
            </Link>

            <div className="d-none d-md-flex gap-5">
              <Link href={`https://blog.rumahdewi.com`}>
                <a target="_blank">
                  <h5 className="fs-6 fw-bold text-white">Blog</h5>
                </a>
              </Link>

              <Link href={`/packages/`}>
                <a target="_blank">
                  <h5 className="fs-6 fw-bold text-white">Harga</h5>
                </a>
              </Link>

              <Link href={`/term-policy`}>
                <a target="_blank">
                  <h5 className="fs-6 fw-bold text-white">Ketentuan</h5>
                </a>
              </Link>
            </div>
          </div>

          <hr className="d-none d-md-block my-0" />

          <div className="d-flex justify-content-between">
            <p className="d-none d-md-block fs-6 mb-0">
              Copyright © {new Date().getFullYear()} by Rumahdewi
            </p>

            <div className="d-flex gap-3 align-items-center">
              <p className="fs-6 mb-0 align-self-center ">Follow us :</p>

              <Link
                href={`https://www.facebook.com/rumahdewicom?mibextid=ZbWKwL`}
              >
                <a target="_blank" className="footer-logo">
                  <i class="bi bi-facebook  text-white" />
                </a>
              </Link>

              <Link href={`https://x.com/rumahdewionline`}>
                <a target="_blank" className="footer-logo">
                  <i class="bi bi-twitter-x text-white" />
                </a>
              </Link>

              <Link
                href={`https://www.instagram.com/rumahdewi.ig?igsh=MTVlYzRuN3M0cm90Yg==`}
              >
                <a target="_blank" className="footer-logo">
                  <i class="bi bi-instagram text-white" />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
